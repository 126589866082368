<template>
    <div class="animated fadeIn">
        <div id="snackbar"></div>
        <b-card>
          <b-row>
              <b-col sm="4">
                  <b-form-group>
                    <label for="date">Organization Name <span class="text-danger">*</span></label>
                    
                    <b-form-select required v-model="organization.selectOrganization" id="cinemas"
                      :plain="true"
                      value-field="OrganizationID"
                      text-field="OrganizationName"
                      :options=organization.organizationList
                      @change.native="onChangeOrganizationDropdwon($event.target.value,'org')"
                      >
                      <template slot="first">
                        <option :value="'0'" disabled>-- Select Organization --</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
               <b-col sm="4">
                  <b-form-group>
                      <label for="date">Select Date <span class="text-danger">*</span></label>
                      <b-form-select required v-model="date.selectedDate" id="date"
                        :plain="true"
                        :options=date.dateList
                        @change.native="onChangeOrganizationDropdwon($event.target.value,'date')"
                        >
                        <template slot="first">
                          <option :value="'0'" disabled>-- Select Date --</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col sm="4">
                  <b-form-group class="mt-10">
                      <label for="date">&nbsp; &nbsp;</label><br/>
                    
                      <b-button v-on:click="onFilterSubmit()" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                  </b-form-group>
              </b-col>
            </b-row>
        </b-card>
        <b-card v-if="data.length > 0">          
            <b-card-header>
              <b-row>
                <b-col class="col" style="text-align: center;">
                  {{ organization.organizationName }}
                  </b-col>
                <b-col class="col" style="text-align: center;">
                    <label for="nooftic">No Of Tickets: {{ organization.noOfTickets }}</label>
                </b-col>
                <b-col class="col" style="text-align: center;">
                    <label for="nooftic">Total: {{ organization.total }}</label>
                </b-col>
                <b-col class="col" style="text-align: center;">
                    <b-form-input :disabled="organization.status" type="text" v-model="organization.inputValue" placeholder="Enter Transfer Id"></b-form-input>
                </b-col>
                <b-col class="col" style="text-align: center;">
                    <b-button :disabled="organization.inputValue == '' ? true: false" @click="onOrganizationSubmit()" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>                  
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
                <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">                   
                  <div slot="TransferId" slot-scope="props">
                      <b-form-input :disabled="props.row.Flag" type="text" v-model="props.row.TransferId" placeholder="Enter Transfer Id"></b-form-input>                      
                    </div>
                    <div slot="Action" slot-scope="props">
                      <b-button :disabled="props.row.Flag" @click="submitTransferId(props.index,props.row.CinemaId,props.row.TransferId,props.row.ID)" type="submit" variant="success" block>Submit</b-button>
                    </div>
                </v-client-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import FinanceService from "@/services/FinanceService";
export default {
  name: "Admin-Finance-Report",
  components: {
    Event,
    cSwitch
  },
  data() {
    return {
      // Saif
      organization: {
        organizationList: [],
        selectOrganization: "0",
        inputValue: "",
        noOfTickets: 0,
        organizationName: "",
        total: 0,
        status: false,
        ids: []
      },

      date: {
        dateList: [
          {
            text: "Select Date",
            value: "0"
          }
        ],
        selectedDate: "0"
      },

      // Saif
      columns: [
        "CinemaName",
        "NoOfTickets",
        "TotalAmount",
        "Date",
        "TransferId",
        "Action"
      ],
      data: [],
      // [
      //   {
      //     CinemaName: "sa",
      //     CinemaId: "sss123",
      //     NoOfTickets: "sasa",
      //     TotalAmount: "sasasasa",
      //     Date: "Date sasa",
      //     TransferId: ""
      //   }
      // ],
      options: {
        headings: {
          CinemaName: "Cinema Name",
          NoOfTickets: "No Of Tickets",
          TotalAmount: "Total Amount",
          Date: "Date",
          TransferId: "Enter Transfer Id"
        },
        sortable: ["CinemaName", "NoOfTickets", "TotalAmount", "Date"],
        filterable: ["CinemaName", "NoOfTickets", "TotalAmount", "Date"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  beforeMount() {},
  mounted() {
    this.getOrganization();
  },
  methods: {
    // Saif
    onChangeOrganizationDropdwon: function(id, name) {
      this.data = [];
      if (name == "org") {
        this.getOrganizationDate(id);
      }
    },

    onOrganizationSubmit: function() {
      let payload = {
        OrgID:
          this.organization.selectOrganization == "0"
            ? ""
            : this.organization.selectOrganization,
        TransferID: this.organization.inputValue,
        Date: this.date.selectedDate == "0" ? "" : this.date.selectedDate,
        ID: this.organization.ids
      };
      // let res = await FinanceService.onCallOrganizationSubmit(payload);
      FinanceService.onCallOrganizationSubmit(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.onFilterSubmit();
            this.organization.selectOrganization = "0";
            this.date.selectedDate = "0";
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    submitTransferId: function(index, CinemaId, TransferID, id) {
      let payload = {
        ID: [id],
        OrgID:
          this.organization.selectOrganization == "0"
            ? ""
            : this.organization.selectOrganization,
        CinemaID: CinemaId,
        TransferID: TransferID,
        Date: this.date.selectedDate == "0" ? "" : this.date.selectedDate
      };

      // let res = await FinanceService.onCallOrganizationSubmit(payload);

      FinanceService.onCallOrganizationSubmit(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.onFilterSubmit();
            this.organization.selectOrganization = "0";
            this.date.selectedDate = "0";
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    getOrganization: function() {
      this.organization.organizationList = [];
      // let res = await FinanceService.getOrganization();
      FinanceService.getOrganization()
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organization.organizationList = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    getOrganizationDate: function(OrgID) {
      this.date.dateList = [];
      this.date.selectedDate = "0";
      // this.date.dateList = [
      //   {
      //     text: "Select Date",
      //     value: "0"
      //   }
      // ];
      // let res = await FinanceService.getOrganizationDate({ OrgID });
      // if (res.data.Status) {
      //   if (res.data.Dates.length > 0) {
      //     res.data.Dates.map(o => {
      //       this.date.dateList.push({
      //         text: o,
      //         value: o
      //       });
      //     });
      //   }
      // }
      FinanceService.getOrganizationDate({ OrgID })
        .then(response => {
          const { data } = response;
          if (data.Status && data.Dates.length > 0) {
            this.date.dateList = data.Dates;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    onFilterSubmit: function() {
      this.data = [];
      if (
        this.organization.selectOrganization !== "0" &&
        this.date.selectedDate !== "0"
      ) {
        let payload = {
          OrgID:
            this.organization.selectOrganization == "0"
              ? ""
              : this.organization.selectOrganization,
          Date: this.date.selectedDate == "0" ? "" : this.date.selectedDate
        };
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", payload);
        // let res = await FinanceService.getFinanceTransferData(payload);
        FinanceService.getFinanceTransferData(payload)
          .then(response => {
            const { data } = response;
            if (data.Status && data.Data.length > 0) {
              this.organization.status =
                data.Data.filter(x => x.Flag).length > 0 ? true : false;

              this.data = data.Data;
              this.organization.noOfTickets = data.Org.NoOfTicketsOrganization;
              this.organization.total = data.Org.TotalAmountOrg;
              // this.organization.inputValue = data.Org.TransferIDOrg;
              this.organization.organizationName = data.Org.Organization;
              this.organization.ids = data.Data.map(x => {
                return x.ID;
              });
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch(error => {
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      } else {
        this.showMessageSnackbar("Please Select Organization & Date");
      }
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
