import Api from './Api'
import Axios from 'axios';

export default {


    getOrganizationDate(payload) {
        return Api().post('api/dashuser/GetOrgDates', payload)
    },
    getOrganization() {
        return Api().post('api/dashuser/GetddlOrgList/')
    },
    getFinanceTransferData(payload) {
        return Api().post('api/dashuser/GetOrgTransferAmount/', payload)
    },
    onCallOrganizationSubmit(payload) {
        return Api().post('api/dashuser/AddTransferID/', payload)
    },
    onCallCinemaSubmit(payload) {
        return Api().post('api/dashuser/GetddlOrgList/', payload)
    }
}